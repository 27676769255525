import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Banded Good Mornings & Lateral Band Walks 4×10`}</p>
    <p>{`Clean Grip Deadlifts 4×4\\@75% Deadlift 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`16:00 EMOM:`}</p>
    <p>{`1:00-16/12 Calorie Row`}</p>
    <p>{`2:00-16 KBS’s (53/35)(RX+ 70/53)`}</p>
    <p>{`3:00-48 Double Unders`}</p>
    <p>{`4:00-16 KB SDHP’s (53/35)(RX+ 70/53)`}</p>
    <p><strong parentName="p">{`*`}{`Tomorrow is our free Partner WOD so bring a friend! Classes are at
9:00 & 10:15am. If you aren’t a member but would like to attend just
email: Daniel\\@crossfittheville.org.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      